import React, { useEffect, useState } from 'react'
import parse from 'html-react-parser'
import ModalC from "./modal"

export const Header = () => {
    const [isOpen, setIsOpen] = useState(false)
    const [swiper, setSwiper] = useState()
    const [success, setSuccess] = useState(false)
    const [intial, setInitial] = useState(true)
    const [initialSlide, setInitialSlide] = useState()

    const HeaderSwiper = React.lazy(() => import("./headerSwiper"))

    const titles = [
        {
            headerTitle: "Квартиры ЖК \"Квaртал 204\" в Ставрополе",
            bgImage: "img/about_img.jpg",
            headerSubTitle: "Семейная ипотека",
            blockTitle: "Семейная ипотека",
        },
        {
            headerTitle: "Квартиры ЖК \"Квaртал 204\" в Ставрополе",
            bgImage: "img/about_img.jpg",
            headerSubTitle: "Военная ипотека",
            blockTitle: "Военная ипотека",
        },
        {
            headerTitle: "г. Ставрополь!",
            bgImage: "img/header_bg.jpg",
            headerSubTitle: "Квартиры с качественным ремонтом от застройщика.",
            blockTitle: "Квартиры с ремонтом <br />от застройщика",
        },
        {
            headerTitle: "г. Ставрополь!",
            bgImage: "img/header_bg.jpg",
            headerSubTitle: "Беспроцентная рассрочка на квартиры напрямую от застройщика!",
            blockTitle: "Беспроцентая рассрочка <br />от застройщика",
        },
    ]

    const slideToIndex = (index) => {
        swiper.slideTo(index)
    }

    const clickItem = (index) => {
        if (intial) {
            setInitial(false)
            setInitialSlide(index)
        } else {
            slideToIndex(index + 1)
        }
    }

    useEffect(() => {
        //getTitle()
        setTimeout(()=>{
            setInitial(false)
        },10000)
    }, [])

    useEffect(()=>{
        if (swiper) {
            slideToIndex(initialSlide)
        }
    },[swiper])



    return (<React.Fragment>
        <div className="header_main">
            {intial ? <div style={{ background: "url('" + titles[3].bgImage + "') no-repeat center bottom" }} className="header plr">
                <div className="wmain">
                    <div className="header_center">
                        <h1>{parse(titles[3].headerTitle)}</h1>
                        <div className="head_sub">{parse(titles[3].headerSubTitle)}</div>
                        <div className="align_center">
                            <a className="btn_main btn_m" onClick={() => { Marquiz.showModal('64062c82124ffc0045887be2') }}>Подобрать квартиру</a>
                        </div>
                    </div>
                </div>
            </div> : <HeaderSwiper titles={titles} refSet={setSwiper}/>}
            <div className="plr">
                <div className="wmain">
                    <ul className="header__list">
                        {titles.map((titleInfo, index) => {
                            return (
                                <li onClick={()=>clickItem(index)}>
                                    <div className="hl__ico"><img src={"img/head_list_ico" + (index + 1) + ".svg"} /></div>
                                    <div className="hl__item">
                                        <div className="hl__name">
                                            {parse(titleInfo.blockTitle)}
                                        </div>
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        </div>
        {isOpen ? <ModalC
            fields={[
                {
                    name: "name",
                    placeholder: "Ваше имя",
                    required: false,
                    type: "text",
                    class: "in_name",
                },
                {
                    name: "phone",
                    placeholder: "Ваш телефон",
                    required: true,
                    type: "text",
                    class: "in_phone",
                },
                {
                    name: "time",
                    placeholder: "Удобное время для звонка",
                    required: true,
                    type: "text",
                    class: "dop-info",
                },
            ]}
            position={window.scrollY}
            hidden={`Заказать звонок`}
            btnTitle={'Заказать звонок'}
            title={"Закажите звонок"}
            celtype={"getCall"}
            template={2}
            personal={true}
            callback={() => { console.log("success") }}
            close={() => { setIsOpen(false) }}
        /> : ""}
        {success ? <ModalC
            success={success}
            position={window.scrollY}
            close={() => { setSuccess(false) }}
        /> : <div></div>}
    </React.Fragment>
    )
}

export default Header
