import { useSendForm } from "../hooks/send-from.hook"
import { PhoneInput } from "./phone_input"

export const Form = ({fields, hidden, className, celtype, btnTitle, template, callback}) => {
  const sendForm = useSendForm()
  return ( <form className={className?className:"pu_form"}>
              {fields.map((field)=>{
                switch(field.type) {
                  case "select":
                    return <select className="select_style dop-info" placeholder={field.placeholder}>
                              <option>{field.placeholder}</option>
                              {field.options.map((option)=> {
                                return <option>{option}</option>
                              })}
                            </select>
                  case "textarea":
                    return <textarea className={"in_style dop-info"} type={field.type} placeholder={field.placeholder} name={field.name} req={field.required?"y":""} />
                  case "text":
                    if (field.name=="phone"){
                      return <PhoneInput />
                    }
                    return <input className={field.class?field.class:"in_style"} type={field.type} placeholder={field.placeholder} name={field.name} req={field.required?"y":""} />
                }
              })}
              <input type="hidden" className="text" value={hidden} />
              <button className="btn_main" template={template} celtype={celtype} onClick={(e)=>{sendForm.sendForm(e, callback);}}>{btnTitle}</button>
          </form>
  )
}