import React, { useContext, useEffect, useState } from "react"
import { Form } from "./form"
import { BlocksContext } from "../context/blocksContext"

export const CityInfra = () => {
    const [success, setSuccess] = useState(false)
    const [showImages, setShowImages] = useState(false)

    const loaded = useContext(BlocksContext)

    const ModalC = React.lazy(() => import("./modal"))

    useEffect(()=>{

        if ((loaded.blocks) > 3) {
            setShowImages(true)
        }

    },[loaded.blocks])
    return (
        <React.Fragment>
            <div className="city_infra plr">
                <div className="wmain">
                    <div className="tm">
                        Сочетание развитой городской инфраструктуры и близость к природе
                    </div>
                    <div className="tm_dop">
                        Жилой комплекс «Квартал 204» находится в Ленинском районе города, который является самым хорошим, с точки зрения экологии, уровню ЖКХ, транспортным развязкам и безопасности.
                    </div>
                    <ul className="city_infra__list">
                        <li>
                            <div className="cil__item">
                                <div className="cil__img"><img src={showImages?"img/infra_list_img1.jpg":""} /></div>
                                <div className="cil__name">Развитая инфраструктура</div>
                                <div className="cil__content">
                                    <div className="cil__name_inner">Развитая инфраструктура</div>
                                    <div className="cil__text">
                                    Остановки общественного транспорта в 2 минутах ходьбы. Транспортная развязка позволит быстро добраться в любой район.
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="cil__item">
                                <div className="cil__img"><img src={showImages?"img/infra_list_img2.jpg":""} /></div>
                                <div className="cil__name">Удачное месторасположение</div>
                                <div className="cil__content">
                                    <div className="cil__name_inner">Удачное месторасположение</div>
                                    <div className="cil__text">
                                        Комплекс находится в непосредственной близости от центральных улиц Ставрополя - Ленина и Мира
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="cil__item">
                                <div className="cil__img"><img src={showImages?"img/infra_list_img3.jpg":""} /></div>
                                <div className="cil__name">Комплекс на возвышении</div>
                                <div className="cil__content">
                                    <div className="cil__name_inner">Комплекс на возвышении</div>
                                    <div className="cil__text">
                                        Любуйтесь завораживающим видом на Кавказский хребет, Эльбрус , а также на весь город.
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="cil__item">
                                <div className="cil__img"><img src={showImages?"img/infra_list_img4.jpg":""} /></div>
                                <div className="cil__name">Магазины рядом</div>
                                <div className="cil__content">
                                    <div className="cil__name_inner">Магазины рядом</div>
                                    <div className="cil__text">
                                        Сетевые продуктовые магазины в шаговой доступности
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="cil__item">
                                <div className="cil__img"><img src={showImages?"img/infra_list_img5.jpg":""} /></div>
                                <div className="cil__name">Спортивный комплекс "Восток-Арена"</div>
                                <div className="cil__content">
                                    <div className="cil__name_inner">Спортивный комплекс "Восток-Арена"</div>
                                    <div className="cil__text">
                                        Водите детей на секции или занимайтесь сами, удобное расположение комплекса позволит вам быстро и удобно добраться  до него - комплекс всего в 300 метрах!
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <Form
                        fields={[
                            {
                                name: "name",
                                placeholder: "Имя",
                                required: false,
                                type: "text",
                                class: "in_style",
                            },
                            {
                                name: "phone",
                                placeholder: "Телефон",
                                required: true,
                                type: "text",
                                class: "in_style",
                            },
                        ]}
                        hidden={`Получить презентацию`}
                        btnTitle={'Получить презентацию'}
                        celtype={"getPresent"}
                        template={2}
                        personal={true}
                        className={"form_style1"}
                        callback={() => { setSuccess(true) }}
                        close={() => { setSuccess(false) }}
                    />
                </div>
            </div>
            {success ? <ModalC
                success={success}
                position={window.scrollY}
                close={() => { setSuccess(false) }}
            /> : <div></div>}
        </React.Fragment>
    )
}

export default CityInfra