import React, { useContext, useEffect, useState } from 'react'
import { BlocksContext } from "../context/blocksContext"
import ModalC from "./modal"

export const Menu = () => {
    const [isOpen, setModalState] = useState(null)
    const [menuOpen, setMenuOpen] = useState(false)
    const blocks = useContext(BlocksContext)

    const clickMenu = (e) => {
        e.preventDefault()
        let popup = e.currentTarget.getAttribute("href")
        if (blocks.blocks < 8) {
            blocks.setMenuClick(true)
            blocks.setBlocks(8)
            setTimeout(() => {
                window.scrollTo({
                    top: document.querySelector("." + popup).offsetTop,
                    behavior: "smooth"
                })
                setTimeout(() => {
                    blocks.setMenuClick(false)
                }, 500)


            }, 1200)

        } else {
            window.scrollTo({
                top: document.querySelector("." + popup).offsetTop,
                behavior: "smooth"
            })
        }
    }

    return (
        <React.Fragment>
            <div className={!menuOpen?"fixed_l":"fixed_l fixed_l__act"} onClick={()=>{setMenuOpen(!menuOpen)}}>
                <div className="fixed_l_logo"><img src="img/menu_small_logo.svg" /></div>
                <div className="fixed_menu_ico">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div className="fixed_menu">
                    <div className="fixed_menu_logo"><img src="img/menu_logo.svg" /></div>
                    <div className="fixed_menu_nav">
                        <a onClick={clickMenu} href="city_infra"><span>Инфраструктура</span></a>
                        <a onClick={clickMenu} href="flat"><span>Планировки и цены</span></a>
                        <a onClick={clickMenu} href="oplata"><span>Как купить</span></a>
                        <a onClick={clickMenu} href="contact"><span>Контакты</span></a>
                        <a href="tel:+78652220599" className='roistat-phone'>+7 (8652) 22-05-99</a>
                    </div>
                    <div className="hl_city">
                        <img src="img/hl_city_ico.svg" />
                        г. Ставрополь, <br />ул. Чапаева д. 4/1
                    </div>
                </div>
            </div>
            <div className="hl_top plr">
                <div className="wmain">
                    <div className="hl_city">
                        <img src="img/hl_city_ico.svg" />
                        г. Ставрополь, <br />ул. Чапаева д. 4/1
                    </div>
                    <div className="logo"><img src="img/logo.svg" /></div>
                    <div className="hl_right">
                        <a className="hl_phone roistat-phone" href="tel:+78652220599">+7 (8652) 22-05-99</a>
                        <a className="btn_main btn_c1" href="#" onClick={(e)=>{e.preventDefault(); setModalState(true)}}>Обратный звонок</a>
                    </div>
                </div>
            </div>
            {isOpen ? <ModalC
                title={"Закажите звонок"}
                position={window.scrollY}
                fields={[
                    {
                        name: "name",
                        placeholder: "Ваше имя",
                        required: false,
                        type: "text",
                        class: "in_style",
                    },
                    {
                        name: "phone",
                        placeholder: "Ваш телефон",
                        required: true,
                        type: "text",
                        class: "in_style",
                    },
                    {
                        name: "time",
                        placeholder: "Удобное время для звонка",
                        required: true,
                        type: "text",
                        class: "in_style dop-info",
                    },
                ]}
                hidden={"Заказать обратный звонок"}
                btnTitle={"Заказать звонок"}
                celtype={"getCall"}
                template={1}
                callback={() => { console.log("success") }}
                personal={true}
                close={() => { setModalState(false) }}
            /> : <div></div>}
        </React.Fragment>
    )
}